import { Grid, Header, Image, Button } from 'semantic-ui-react';
import Products from './../../assets/Products.jpg';
import TechnologyImg from './../../assets/Technology.jpg';
import { Link } from 'react-router-dom';
const Technology = () => (
  <div style={{ padding: '0em', marginBottom: '4em' }} vertical>
    <Grid>
      <Grid.Row centered>
        <Grid.Column mobile={14} computer={12}>
          <div className='technology-container'>
            <Grid celled='internally' columns='equal' stackable>
              <Grid.Row textAlign='center'>
                <Grid.Column style={{boxShadow: 'none'}}>
                  <Image src={Products} fluid rounded />
                </Grid.Column>
                <Grid.Column verticalAlign='middle' style={{boxShadow: 'none'}}>
                  <Header as={'h3'} className='poppins-font tech-heading'>
                    What are we up to?
                  </Header>
                  <div className='poppins-font tech-description'>
                    We are creating value from emissions &amp; industry side streams for meeting nutrient &amp; food security
                    without adding more carbon emission.
                  </div>
                  <div style={{ marginTop: '2em' }}>
                    <Button as={Link} to="/products" color='blue' className='poppins-font green-button'>View Products</Button>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row textAlign='center' style={{boxShadow: 'none'}}>
                <Grid.Column verticalAlign='middle'>
                  <Header as={'h3'} className='poppins-font tech-heading'>
                    How are we doing it?
                  </Header>
                  <div className='poppins-font tech-description'>
                    Converting the problem into solution
                    We are decarbonizing industries in the process to make alternative ingredients.
                  </div>
                  <div style={{ marginTop: '2em' }}>
                    <Button as={Link} to="/technology" color='blue' className='poppins-font green-button'>View Technology</Button>
                  </div>
                </Grid.Column>
                <Grid.Column style={{boxShadow: 'none'}}>
                  <Image src={TechnologyImg} fluid rounded />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
);



export default Technology;