import { Grid, Segment, Image } from 'semantic-ui-react';
import Labs from './assets/Labs.jpg';
import Birac from './assets/Birac.png';

const Partnerships = () => (
  <Grid.Row>
    <Grid.Column width={14} className='info-container'>
      <div className='info-title small-title poppins-font'>Partnerships</div>
      <div style={{ marginBottom: '1rem'}} className='poppins-font'>Selected in the Indigram Labs – BIRAC Bio-Nest cohort of 2024</div>
      <Segment vertical>
        <Grid container stackable verticalAlign='middle'>
          <Grid.Row>
            <Grid.Column  width={8}>
              <Image rounded size='medium' src={Labs} style={{margin: 'auto'}} />
            </Grid.Column>
            <Grid.Column width={8}>
              <Image rounded size='medium' src={Birac} style={{margin: 'auto'}} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Grid.Column>
  </Grid.Row>
);

export default Partnerships;