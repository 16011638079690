import React from 'react';
import Container from '../Container';
import { Grid, Image, Header, List, ListItem } from 'semantic-ui-react';
import Impact1 from './assets/Impact1.jpg';
import Impact2 from './assets/Impact2.jpg';
import Impact3 from './assets/Impact3.jpg';
import Goal2 from './assets/Goal2.jpg';
import Goal3 from './assets/Goal3.jpg';
import Goal12 from './assets/Goal12.jpg';
import Goal13 from './assets/Goal13.jpg';
import './index.css';

const index = () => {
  return (
    <Container>
      <div>
        <h1 className='product-heading poppins-font'>Our Impact</h1>
      </div>
      <Grid centered stackable columns={2} style={{ padding: '2em 0 4em' }}>
        <Grid.Row stretched style={{marginBottom: '4rem'}}>
          <Grid.Column width={6} verticalAlign='top'>
            <Image src={Impact1} verticalAlign='middle' rounded/>
          </Grid.Column>
          <Grid.Column width={6} verticalAlign='top'>
            <Header as={'h2'} className='poppins-font' style={{textAlign: 'left'}}>Decarbonization & Upcycle carbon</Header>
            <p style={{ fontSize: '1.33em', textAlign: 'justify' }} className='poppins-font'>
              Our technology can decarbonize the alcohol and ethanol industry by using its Carbon emissions and side streams.
            </p>
            <p style={{ fontSize: '1.33em', textAlign: 'justify', marginBottom: 0, marginTop: '2rem' }} className='poppins-font'>
              By upcycling carbon we enable a circular carbon ecosystem in which Carbon is captured – utilized &
              converted into a valuable ingredient that
            </p>
            <List bulleted style={{ fontSize: '1.33em', textAlign: 'justify' }}>
              <ListItem className='poppins-font'>Displaces traditional means &amp; materials of production</ListItem>
              <ListItem className='poppins-font'>Avoids future emissions</ListItem>
              <ListItem className='poppins-font'>Lead to new innovations that will disrupt incumbent inefficient systems</ListItem>
            </List>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row stretched style={{marginBottom: '4rem'}}>
          <Grid.Column width={6}>
            <Header as={'h2'} className='poppins-font'>Sustainable Development Goals</Header>
            <List style={{ fontSize: '1.33em', textAlign: 'justify' }}>
              {[{
                image: Goal2,
                title: 'Goal 2',
                description: 'Creates resilience across food supply chain'
              }, {
                image: Goal3,
                title: 'Goal 3',
                description: 'Nutritional attributes'
              }, {
                image: Goal12,
                title: 'Goal 12',
                description: 'Carbon negative process'
              }, {
                image: Goal13,
                title: 'Goal 13',
                description: 'Avoids future emissions'
              }].map(goal => (
                <ListItem className='poppins-font'>
                  <div style={{ display: 'flex', flexDirection: 'row'}}>
                    <div>
                      <Image src={goal.image} size='tiny'/>
                    </div>
                    <div style={{height: '100%', marginLeft: '1rem'}}>
                      <div style={{ fontWeight: 'bold'}}>{goal.title} :</div>
                      <div style={{ marginTop: '1rem'}}>
                        {goal.description}
                      </div>
                    </div>
                  </div>
                </ListItem>
              ))}
            </List>
          </Grid.Column>
          <Grid.Column width={6} verticalAlign='middle'>
            <Image src={Impact2} verticalAlign='middle' rounded/>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row stretched>
          <Grid.Column width={6} verticalAlign='middle'>
            <Image src={Impact3} verticalAlign='middle' rounded/>
          </Grid.Column>
          <Grid.Column width={6} verticalAlign='top'>
            <Header as={'h2'} className='poppins-font' textAlign='left'>Lesser exploitation of the planet</Header>
            {/* <p style={{ fontSize: '1.33em', textAlign: 'justify' }} className='poppins-font'>
              By upcycling carbon we enable a circular carbon ecosystem in which Carbon is captured – utilized &
              converted into a valuable ingredient that
            </p> */}
            <List bulleted style={{ fontSize: '1.33em', textAlign: 'justify' }}>
              <ListItem className='poppins-font'style={{padding: '0.5rem 0'}} >
                Our fermented process created as much protein as soybean but in just 7% of the land area 
                compared to what it needs for soybean farming
              </ListItem>
              <ListItem className='poppins-font' style={{padding: '0.5rem 0'}}>The process is Climate, Geography &amp; Rainfall independent</ListItem>
              <ListItem className='poppins-font' style={{padding: '0.5rem 0'}}>
                1 Ton of fermented animal feed could reduce the use of 33tons of soybean meal &amp; its
                related carbon footprint.
              </ListItem>
              <ListItem className='poppins-font' style={{padding: '0.5rem 0'}}>
                Land released from monoculture farming can be used for afforestation &amp; become natural
                carbon sinks
              </ListItem>
            </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

const ImageColumn = ({ image }) => (
  <Grid.Column width={6} verticalAlign='middle'>
    <Image src={image} verticalAlign='middle' rounded/>
  </Grid.Column>
);

const DataColumn = ({ product }) => (
  <Grid.Column width={6}>
    <div id={product.id}></div>
    <Header as={'h2'} className='poppins-font'>{product.name}</Header>
    <p style={{ fontSize: '1.33em', textAlign: 'justify' }} className='poppins-font'>
      {product.details}
    </p>
    <List bulleted style={{ fontSize: '1.33em', textAlign: 'justify' }}>
      {product.points.map((point) => (
        <ListItem className='poppins-font'>{point}</ListItem>
      ))}
    </List>
  </Grid.Column>
)

export default index;