import React, { useState } from "react";
import {Container, Icon, Menu, Segment, Sidebar } from 'semantic-ui-react';
import HomepageHeading from '../Heading';
import { SidebarMenu } from "../../Container/MobileContainer";

const MobileContainer = ({ children, Media }) => {
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const handleSidebarHide = () => setSidebarOpened(false);
  const handleToggle = () => setSidebarOpened(!sidebarOpened);

  return (
    <Media as={Sidebar.Pushable} at='mobile'>
      <Sidebar.Pushable>
        <Sidebar as={Menu} animation='overlay' inverted onHide={handleSidebarHide} vertical visible={sidebarOpened}>
          <SidebarMenu />
        </Sidebar>
        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment inverted textAlign='center' style={{ minHeight: 350, padding: '1em 0em' }} vertical>
            <Container>
              <Menu inverted pointing secondary size='large'>
                <Menu.Item onClick={handleToggle}>
                  <Icon name='sidebar' />
                </Menu.Item>
              </Menu>
            </Container>
            <HomepageHeading mobile />
          </Segment>
          {children}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </Media>
  );
}

export default MobileContainer;