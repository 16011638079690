import 'semantic-ui-css/semantic.min.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
// import WIP from '../modules/WIP';
import Home from '../modules/NewHome';
import ContactUs from '../modules/ContactUs/ContactUs';
import Products from '../modules/products';
import Technology from '../modules/Technology';
import Impact from '../modules/Impact';
import AboutUs from '../modules/AboutUs';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
  },
  {
    path: "/products",
    element: <Products />,
  },
  {
    path: "/technology",
    element: <Technology />,
  },
  {
    path: "/impact",
    element: <Impact />,
  },
  {
    path: "/about-us",
    element: <AboutUs />,
  }
]);

const RoutesProivder = () => (
  <RouterProvider router={router} />
);

export default RoutesProivder;