import React, { useState } from "react";
import { Container, Icon, Menu, Segment, Sidebar, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Logo from './../../assets/Logo.png';

const MobileContainer = ({ children, Media }) => {
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const handleSidebarHide = () => setSidebarOpened(false);
  const handleToggle = () => setSidebarOpened(!sidebarOpened);

  return (
    <Media as={Sidebar.Pushable} at='mobile'>
      <Sidebar.Pushable>
        <Sidebar as={Menu} animation='overlay' inverted onHide={handleSidebarHide} vertical visible={sidebarOpened}>
          <SidebarMenu />
        </Sidebar>
        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment inverted textAlign='center' style={{ padding: '1em 0em' }} vertical>
            <Container>
              <Menu inverted pointing secondary size='large'>
                <Menu.Item onClick={handleToggle}>
                  <Icon name='sidebar' />
                </Menu.Item>
              </Menu>
            </Container>
          </Segment>
          {children}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </Media>
  );
}

export const SidebarMenu = () => (
  <>
    <Menu.Item as={Link} to="/" className="menu">
      <Image src={Logo} size="small" />
    </Menu.Item>
    <Menu.Item as={Link} to="/" className="menu">Home</Menu.Item>
    <Menu.Item as={Link} to="/products" className="menu">Products</Menu.Item>
    <Menu.Item as={Link} to="/technology" className="menu">Technology</Menu.Item>
    <Menu.Item as={Link} to="/impact" className="menu">Impact</Menu.Item>
    <Menu.Item as={Link} to="/about-us" className="menu">About Us</Menu.Item>
  </>
)

export default MobileContainer;