import { Grid, Segment, Image, Header } from 'semantic-ui-react';
import Shriyansh from './../../assets/team/Shriyansh.jpg';
import Vilas from './../../assets/team/Vilas.jpg';
import Samay from './../../assets/team/Samay.jpg';

const Team = () => (
  <Grid.Row>
    <Grid.Column width={14} className='info-container'>
      <div className='info-title poppins-font small-title'>Our Team</div>
      <Segment vertical style={{ paddingBottom: '4em' }}>
        <Grid container stackable verticalAlign='top'>
          <Grid.Row centered>
            <Grid.Column width={6} className='team-card'>
              <Header as='h3' style={{ fontSize: '2em' }} className='poppins-font'>
                Founder
              </Header>
              <Image bordered rounded size='small' src={Shriyansh} style={{height: '180px', width: 'auto'}}/>
              <Header as='h4' style={{ fontSize: '1.5em' }} className='poppins-font'>Shriyansh Sonawane</Header>
              <p className='team-card-content poppins-font'>
                Ex- UPSC Civil Services Aspirant <br/>
                MBA – Symbiosis International University <br/>
                BE – Mumbai University
              </p>
            </Grid.Column>
            <Grid.Column width={5} className='team-card'>
              <Header as='h3' style={{ fontSize: '2em' }} className='poppins-font'>
                Honorary Advisor
              </Header>
              <Image bordered rounded size='small' src={Vilas} style={{height: '180px'}}/>
              <Header as='h4' style={{ fontSize: '1.5em' }} className='poppins-font'>Dr. Vilas Gaikar</Header>
              <p className='team-card-content poppins-font'>
                Bharat Petroleum Distinguished Professor in Chemical Engineering <br/>
                Ex Independent BoD- Aarti Drugs Ltd, <br/>
                Coordinator- UGC- National Resource Centre in chemical engineering <br/>
                Research Area: Photochemical reduction of CO2, Thermochemical conversion of Biomass <br/>
                11 Patents &amp; 2 Books published <br/>
                Ph.D., ME &amp; BE - Chemical Engineering
              </p>
            </Grid.Column>
            <Grid.Column width={5} className='team-card'>
              <Header as='h3' style={{ fontSize: '2em' }} className='poppins-font'>
                Scientific Advisor
              </Header>
              <Image bordered rounded size='small' src={Samay} style={{height: '180px'}}/>
              <Header as='h4' style={{ fontSize: '1.5em' }} className='poppins-font'>Dr. Samay Pande</Header>
              <p className='team-card-content poppins-font'>
                Assistant Professor at the Indian Institute of Science <br/>
                PhD (Max Planck Institute, Jena Germany) <br/>
                Indian Young Biotechnologist Awardee from DBT-India <br/>
                Max Planck Partner Group leader <br/>
                India Alliance Welcome-DBT intermediate fellow <br/>
                Research area: Microbiology, Genetics, Antimicrobial resistance, Antibiotics
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Grid.Column>
  </Grid.Row>
);

export default Team;