import React from "react";
import { Header } from 'semantic-ui-react';

const HomepageHeading = ({ mobile }) => (
  <div className="homepage-heading">
    <div className="title-container">
      <div className="title-wrapper">
        <div className="heading-container">
          <Header
            as='h1'
            content='Harnessing Carbon to Enable Circular Economy'
            className="poppins-font"
            style={{
              fontSize: mobile ? '2em' : '4.25em',
              fontWeight: 'bold',
              color: '#aaa',
              textAlign: 'center',
              marginBottom: '2rem'
            }}
          />
        </div>
        <div className="sub-heading-container">
          <Header
            as='h2'
            className="poppins-font"
            content='Terramatter aims at putting Carbon emissions to use to make critical & specialty chemicals 
              like Single cell Proteins (SCP) thereby creating a positive spiral of circular carbon economy.'
            style={{
              fontSize: mobile ? '1.15em' : '1.7em',
              fontWeight: 'normal',
              color: '#bbb',
              textAlign: 'center',
            }}
          />
        </div>
      </div>
    </div>
  </div>
);

export default HomepageHeading;