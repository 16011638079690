import { Grid } from 'semantic-ui-react';

const Mission = () => (
  <Grid.Row>
    <Grid.Column width={14} className='info-container poppins-font'>
      <div className='info-title poppins-font small-title'>Mission</div>
      <div className='poppins-font info-description'>
        To help our planet thrive, Terramatter works on reducing emissions using innovative climate friendly
        technologies
      </div>
    </Grid.Column>
  </Grid.Row>
);

export default Mission;