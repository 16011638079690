import { createMedia } from '@artsy/fresnel';
import React from 'react';

import DesktopContainer from './DesktopContainer';
import MobileContainer from './MobileContainer';
import Footer from './Footer';
import './index.css';

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

const ResponsiveContainer = ({ children }) => (
  <MediaContextProvider>
    <DesktopContainer Media={Media}>{children}</DesktopContainer>
    <MobileContainer Media={Media}>{children}</MobileContainer>
  </MediaContextProvider>
)

const HomepageLayout = ({ children}) => (
  <ResponsiveContainer>
    {children}
    <Footer />
  </ResponsiveContainer>
);

export default HomepageLayout;
