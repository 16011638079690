import { Grid } from 'semantic-ui-react';

const AboutUs = () => (
  <Grid.Row>
    <Grid.Column width={14} className='info-container poppins-font' style={{ paddingTop: '4rem'}}>
      <div className='info-title poppins-font'>About Us</div>
      <div className='poppins-font info-description'>
        <span className='poppins-font info-description' style={{ color: '#4c874b', fontWeight: 'bold' }}>Terramatter</span> is a deep science climate technology company.
        We are on a mission to harness carbon to enable a circular carbon economy by which we can create resilient systems to overcome the 
        challenges posed by climate change on our planet and in particular our food production systems.
      </div>
    </Grid.Column>
  </Grid.Row>
);

export default AboutUs;