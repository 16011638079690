import React from 'react';
import Layout from '../Layout/Layout';
import Form from './Form';

const ContactUs = () => {
  return (
    <Layout>
      <Form />
    </Layout>
  );
};

export default ContactUs;