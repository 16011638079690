
import React, { useState } from 'react';
import {
  Menu,
  Segment,
  Sidebar,
  Icon,
} from 'semantic-ui-react';
import { useNavigate, Link } from 'react-router-dom';

const MobileContainer = ({ children }) => {
  const navigate = useNavigate();
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const onNavigate = (path) => {
    console.log(path, 'called');
    navigate(path);
    setSidebarOpened(false);
  };

  return (
    <Sidebar.Pushable>
      <Sidebar as={Menu} animation='overlay' inverted onHide={() => setSidebarOpened(false)} vertical visible={sidebarOpened}>
        <Menu.Item as='a' onClick={() => onNavigate('/home')}>Home</Menu.Item>
        <Menu.Item as={Link} to="/contact-us">Contact Us</Menu.Item>
      </Sidebar>
      <Sidebar.Pusher dimmed={sidebarOpened}>
        <Segment inverted textAlign='center' vertical>
            <Menu inverted pointing secondary size='medium'>
              <Menu.Item onClick={() => setSidebarOpened(!sidebarOpened)}>
                <Icon name='sidebar' />
              </Menu.Item>
              <Menu.Item position='right' as='a' href="/home">Home</Menu.Item>
              <Menu.Item position='right' as='a' href="/home#products">Products</Menu.Item>
              <Menu.Item position='right' as='a' href="/home#team">Team</Menu.Item>
              <Menu.Item position='right' as='a' href="/home#impact">Impact</Menu.Item>
            </Menu>
        </Segment>
        {children}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  )
}

export default MobileContainer;