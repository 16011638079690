import React from 'react';
import Container from '../Container'
import './index.css';
import { Divider, Grid, Image, Segment } from 'semantic-ui-react';
import { STAGES } from './data';

const index = () => {
  return (
    <Container>
      <div className='technology-heading poppins-font' style={{marginTop: '4rem'}}>TECHNOLOGY – Carbon offset solution for industries</div>
      <div className='technology-description poppins-font'>
        Our process involves Carbon Capture at point source which is then converted into essential ingredients through a biological fermentation process. For this we have deployed organisms that can convert CO2 into a usable source of energy.
      </div>
      <div className='technology-description poppins-font'>
        Such Carbon capture and Conversion (CCC) could very well decarbonize industries emitting CO2 and help nations and the planet reach Net zero by 2050.
      </div>
      <div className='technology-description poppins-font'>
        Furthermore, by putting microorganisms to use, we are enabling 2nd domestication of organisms that can create resilience across of the various production systems making food, feed and ingredients. This could potentially solve the problem of achieving nutrient & food security in a sustainable way without further harming the planet.
      </div>
      <div className='technology-description poppins-font'>
        Our process is Carbon fed which makes it averse to feed availability risks, is scalable and independent of climate, rainfall and geography – making it ideal to be setup at any location with basic infrastructure.
      </div>
      <div className='technology-description poppins-font'>
        It can be easily collocated with Ethanol and Alcohol companies to use their CO2 emissions and other side streams thus enabling a truly waste to value circular carbon economy.
      </div>
      <Segment stackable style={{margin: '2rem 0 0 0', padding: '2rem',paddingTop: '4rem', backgroundColor: '#a0a0a0'}}>
        <Grid stackable>
          <Grid.Row centered>
            <Grid.Column width={6}>
              <div className='process-heading poppins-font'>Our process</div>
              <div className='process-description poppins-font'>Learn about our process in detail:</div>
            </Grid.Column>
            <Grid.Column width={10}>
              <Grid stackable>
                {STAGES.map((stage, index) => (
                  <ProcessStage image={stage.image} title={stage.title} description={stage.description} isLastIndex={index === STAGES.length - 1}/>
                ))}
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Container>
  );
};

const ProcessStage = ({ image, title, description, isLastIndex }) => (
  <Grid.Row>
    <Grid.Column width={4}>
      <div className='technology-image'>
        <Image src={image}/>
      </div>
    </Grid.Column>
    <Grid.Column width={12}>
      <div className='poppins-font' style={{fontSize: '1.25rem', paddingBottom: '1rem'}}>
        <div style={{ fontWeight: 'bold', fontSize: '1.5rem', marginBottom: '1rem' }}>
         {title}
        </div>
        {description}
      </div>
      {!isLastIndex && <Divider />}
    </Grid.Column>
  </Grid.Row>
)

export default index;