import React from 'react';
import { Segment, Grid, Container, List, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Logo from './../../assets/Logo.png';

const Footer = () => {
  return (
    <Segment inverted vertical style={{ paddingTop: '5em' }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <List link inverted>
                <List.Item as='a' href="/home#products">Products</List.Item>
                <List.Item as='a' href="/home#team">Team</List.Item>
                <List.Item as='a' href="/home#impact">Impact</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <List link inverted>
                <List.Item as={Link} to="/contact-us">Contact Us</List.Item>
                <List.Item as='a'>LinkedIn</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={7}>
              <Image src={Logo} size='small' />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <div className='copy-right'>© 2024 Terramatter Climate Technologies Pvt. Ltd. All Rights Reserved.</div>
    </Segment>
  );
};

export default Footer;