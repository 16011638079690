import Stage1 from './assets/Stage1.svg';
import Stage2 from './assets/Stage2.svg';
import Stage3 from './assets/Stage3.svg';

export const STAGES = [{
  image: Stage1,
  title: 'Stage 1',
  description: 'CO2 fermentation: CO2 is fed to organisms that feed on it and the output of this stage is passed to the 2nd stage ',
}, {
  image: Stage2,
  title: 'Stage 2',
  description: 'Fermentation: Here the output from CO2 fermentation is taken up by organisms that grow in a controlled environment to produce the various end products.',
}, {
  image: Stage3,
  title: 'Stage 3',
  description: 'Product is then extracted from the rest of the biomass and purified which is then taken up as an ingredient for various use cases.',
}]