import React from 'react';
import Container from '../Container';
import './index.css';
import { Grid } from 'semantic-ui-react';

import AboutUs from './AboutUs';
import Mission from './Mission';
import Vision from './Vision';
import Team from './Team';
import Partnerships from './Partnerships';
import Featured from './Featured';

const index = () => {
  return (
    <Container className="about-us-container">
      <Grid centered>
        <AboutUs />
        <Mission />
        <Vision />
        <Team />
        <Partnerships />
        <Featured />
      </Grid>
    </Container>
  );
};

export default index;