import React from 'react';
import Container from './Container';
import Impact from './Impact';
import Technology from './Technology';
import './index.css';

const Index = () => {
  return (
    <Container>
      <Impact />
      <Technology />
    </Container>
  );
};



export default Index;