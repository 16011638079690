import {
  Image,
  Container,
  Menu,
  Segment,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import './home.css';

import Logo from './../../../assets/Logo.png';

const DesktopContainer = ({ children }) => (
  <>
    <Segment inverted className='desktop-menu-container' vertical>
      <Menu size='large' className='menu-container'>
        <Container>
          <Menu.Item as={Link} to="/home" style={{borderRight: 0, backgroundColor: '#38493d'}}>
            <Image src={Logo} size="small" />
          </Menu.Item>
          <Menu.Item position='right'>
            <Menu.Item as='a' href="/home#products">Products</Menu.Item>
            <Menu.Item as='a' href="/home#team">Team</Menu.Item>
            <Menu.Item as='a' href="/home#impact">Impact</Menu.Item>
            <Menu.Item as={Link} to="/contact-us">Contact Us</Menu.Item>
          </Menu.Item>
        </Container>
      </Menu>
    </Segment>
    {children}
  </>
)

export default DesktopContainer;