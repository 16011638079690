import { Grid } from 'semantic-ui-react';

const Vision = () => (
  <Grid.Row>
    <Grid.Column width={14} className='info-container'>
      <div className='info-title poppins-font small-title'>Vision</div>
      <div className='poppins-font info-description'>
        To strive towards maintaining a habitable &amp; sustainable Earth through bringing about a behavioral
        change in the way we utilize material resources &amp; their byproducts in the production lifecycle thus
        becoming a catalyst of circular carbon economy.
      </div>
    </Grid.Column>
  </Grid.Row>
);

export default Vision;