import { Link } from 'react-router-dom';
import { Button, Grid } from 'semantic-ui-react';

const Impact = () => (
  <div className='impact-container'>
    <Grid container stackable verticalAlign='top'>
      <Grid.Row>
        <Grid.Column width={8}>
          <div className="impact-quote poppins-font">
            “The Earth is the only world known so far to harbor life. There is nowhere
            else, at least in the near future, to which our species could migrate. Visit,
            yes. Settle, not yet. Like it or not, for the moment the Earth is where we
            make our stand.”
          </div>
          <div className='impact-quote-author poppins-font'>— Carl Sagan, Pale Blue Dot, 1994</div>
        </Grid.Column>
        <Grid.Column width={8}>
          <div className='impact-description poppins-font'>
            We at Terramatter took this inspiration from Carl Sagan and endeavor to help our planet overcome
            the challenges posed by climate change.
          </div>
          <div className='impact-button poppins-font'>
            <Button as={Link} to="/impact" color='blue' className='poppins-font green-button'>Learn More</Button>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
);

export default Impact;