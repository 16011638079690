import { createMedia } from '@artsy/fresnel'
import PropTypes from 'prop-types'
import React from 'react'
import { Sidebar } from 'semantic-ui-react';

import Desktop from './Desktop';
import Mobile from './Mobile';
import Footer from './footer';

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

const DesktopContainer = ({ children }) => (
  <Media greaterThan='mobile'>
    <Desktop>{children}</Desktop>
  </Media>
);

const MobileContainer = ({ children }) => (
  <Media as={Sidebar.Pushable} at='mobile'>
    <Mobile>{children}</Mobile>
  </Media>
)

const ResponsiveContainer = ({ children }) => (
  <MediaContextProvider>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </MediaContextProvider>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

const HomepageLayout = ({ children }) => (
  <ResponsiveContainer>
    {children}
    <Footer />
  </ResponsiveContainer>
)

export default HomepageLayout;