import React from 'react';
import { Container, Grid, Header, List, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <Segment inverted vertical style={{ padding: '5em 0em' }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <Header inverted as='h4' content='About'className='poppins-font' />
              <List link inverted>
                <List.Item as={Link} to="/about-us" className="menu poppins-font">About Us</List.Item>
                <List.Item as={Link} to="/contact-us" className="menu poppins-font">Contact Us</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header inverted as='h4' content='Services' className='poppins-font'/>
              <List link inverted>
                <List.Item as={Link} to="/products" className="menu poppins-font">Products</List.Item>
                <List.Item as={Link} to="/technology" className="menu poppins-font">Technology</List.Item>
                <List.Item as={Link} to="/impact" className="menu poppins-font">Impact</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={7}>
              <List link inverted>
                <List.Item as='a' href="https://www.linkedin.com/in/shriyanshsonawane/" target="_blank" className='poppins-font'>Shriyansh Sonawane</List.Item>
                <List.Item as='a' className='poppins-font'>+91-7718897688</List.Item>
              </List>
              <p style={{ fontSize: '0.75em', textAlign: 'justify' }} className='poppins-font'>
                © 2024 Terramatter. All Rights Reserved.
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
};

export default Footer;